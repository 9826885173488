var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"Main width_1200 margin_auto flex justify_content_sb",staticStyle:{"padding-bottom":"20px"}},[_c('div',{staticClass:"MainLeft"},[_c('div',{staticClass:"swiperBox"},[_c('el-carousel',_vm._l((_vm.bnnerLists),function(item,index){return _c('el-carousel-item',{key:index},[_c('img',{key:item.pic,staticStyle:{"object-fit":"cover"},attrs:{"src":item.pic,"alt":""}})])}),1)],1),_c('div',{staticClass:"rsTitle flex align_items_c margin_top_30"},[_c('div',{staticClass:"rsTitleLeft flex align_items_c"},[_c('div',{staticStyle:{"color":"#000000","font-weight":"bold"}},[_vm._v("各地政策")]),_c('div',{staticClass:"flex align_items_c pointer",on:{"click":function($event){return _vm.$router.push({
								path: '/gw_dex',
								query: { id: 5, name: '各地政策' },
							})}}},[_vm._v(" 更多 "),_c('i',{staticClass:"el-icon-arrow-right"})])])]),_c('div',{staticClass:"flex justify_content_sb",staticStyle:{"margin-top":"18px"}},[_c('div',{staticClass:"zcList"},_vm._l((_vm.labelDetails),function(item,index){return _c('div',{key:index,staticClass:"zcItem pointer",on:{"click":function($event){return _vm.$router.push({
								path: '/gw_detail',
								query: { id: item.id },
							})}}},[_c('div',{staticClass:"ellipsis_one hovercolor"},[_vm._v(_vm._s(item.title))]),(item.author !== null)?_c('div',[_vm._v(" "+_vm._s(item.author.nickname)+" . "+_vm._s(item.release_time)+" ")]):_c('div',[_vm._v(_vm._s(item.release_time))])])}),0)]),_c('div',{staticClass:"tageBox"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.categoryLists),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item.name,"name":item.id + ''}},[(_vm.articleList.length > 0)?_c('div',[_vm._l((_vm.articleList),function(items,indexs){return _c('div',{key:indexs,staticClass:"wzitem flex align_items_c pointer",on:{"click":function($event){return _vm.$router.push({
										path: '/gw_detail',
										query: { id: items.id, title: items.title },
									})}}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":items.pic,"alt":""}}),_c('div',{staticClass:"wzitemL"},[_c('div',{staticClass:"wzitemLTitle"},[_c('div',{staticClass:"ellipsis_one hovercolor",staticStyle:{"color":"#000000","font-weight":"bold"}},[_vm._v(" "+_vm._s(items.title))]),_c('div',[_vm._v(_vm._s(items.illustrate))])]),_c('div',{staticClass:"wzitemLTime flex align_items_c justify_content_sb"},[_c('div',{staticClass:"wzitemLTime_l"},[_vm._v("MetaEra · "),_c('span',{directives:[{name:"time",rawName:"v-time",value:(items.release_time),expression:"items.release_time"}]})]),_c('div',{staticClass:"flex align_items_c lookNum"},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH5SURBVHgB7VXBUQJBEJxZqiykfFwGnhGIEQgZyEMt/HAbgRgBEIESwcLLAh+QgWQARCBmwAstq7h15m454Gr3OP7Mi73d6Z7p7R0ATnEgMM8hpd59AFEGCL34i1hS6kLKh9mhXHSDKk+Ii2etw4CWvuPYgiAmWq87UtYXuQn6/Y+W1roJoL0U0HeUhOLakJYTIBTtRuO+k0nAUiAWFAFXeE0kXZKj55Iilg7aBN4wn2bUcW23G9wHF59cGQHPCTjIo3E6l7slkuqGBOMDykMsTbfgPxUp5RKOCKVGhPHHJOW4k1WVMUTEgqXWIXAugioNlBrc2QikrC21PqsyOJMYTEClhrTQ03Rr6ej1Bl+wdROdXd3YC4nuccoGYTyBCK+8QQun1UzV/s4n+l10dFLnQiM3EVFLbLfQA2eElj2dcUfbPcGPJGbDlrGdJYpjiN5CEvSKn8a2k8ZRkf5aw4ugliax34FcIEZ8mekkvsAgeLyihBq1L1l/OzjnIhOzYbpsc2NTu8XgiIjBzyekBL/yxARiUyG/QCMDW2zqlssGPizvg4dJgZZRIUaQzBjsZQ0yY3EeE01znueVtI6KFFF7c1EmZqayeZSEhUszr3wDQwqsSfN6O42VMa6TQXYLGeOaSPsAv2+uO8v5h8Maa3//DyecuaQ7xVHxD4rDJjfZUTFuAAAAAElFTkSuQmCC","alt":""}}),_c('div',[_vm._v(_vm._s(items.like_num))])])])])])}),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > 0),expression:"total > 0"}],attrs:{"total":_vm.total,"page":_vm.page,"limit":_vm.size},on:{"update:page":function($event){_vm.page=$event},"update:limit":function($event){_vm.size=$event},"pagination":_vm.getDataListPage}})],2):_vm._e()])}),1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }